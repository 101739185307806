
















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import VButton from '@/components/VButton.vue';
import { Mutation } from 'vuex-class';
import { goToVue3AppUrl } from '@/store/adapter';

@Component({ components: { VButton }})
export default class AcceptTermsModal extends Vue {
  @Prop({ required: true }) private modalId!: string;
  @Prop({ default: false }) private show!: boolean;
  @Mutation('ACCEPT_TERMS', { namespace: 'main' }) private acceptTerms!: () => void;

  private goToVue3 = goToVue3AppUrl;

  @Watch('show')
  private onShowChanged(value: boolean, oldValue: boolean) {
    if (value) {
      (this.$refs[this.modalId] as any).show();
    } else {
      (this.$refs[this.modalId] as any).hide();
    }
  }
}
